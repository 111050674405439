import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/Layout/index.vue'
// import store from '@/store'

import account from './modules/account'
import bill from './modules/bill'
import collect from './modules/collect'
import quotedPrice from './modules/quoted-price'
import product from './modules/product'

Vue.use(VueRouter)

export const routes = [
  {
    path: '/login',
    name: 'Login',
    hidden: true,
    meta: { notNeedAuth: true },
    component: () => import('@/views/login/login.vue'),
  },
  {
    path: '/passwordReset',
    name: 'passwordReset',
    hidden: true,
    meta: { notNeedAuth: true },
    component: () => import('@/views/login/password-reset.vue'),
  },
  {
    path: '/404',
    name: 'NotFund',
    hidden: true,
    meta: { hidden: true },
    component: () => import('@/views/Error/404.vue'),
  },
]
export const permissionRoutes = [
  {
    path: '/',
    name: 'Root',
    redirect: '/home',
    component: Layout,
    children: [
      {
        path: 'home',
        name: 'Home',
        hidden: true,
        meta: { title: '首页', icon: 'el-icon-s-home', hidden: true, fixed: true ,requireAuth: true },
        component: () => import('@/views/home/home.vue'),
      },
    ],
  },
  account,
  bill,
  collect,
  quotedPrice,
  product,
  {
    path: '*',
    name: 'Error',
    hidden: true,
    redirect: '/404',
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
})

export function resetRouter() {
  const newRouter = new VueRouter({
    scrollBehavior: () => ({ y: 0 }),
    routes: routes,
  })
  router.matcher = newRouter.matcher
}

router.beforeEach((to, from, next) => {
  if (to.meta.requireAuth) {
    var token = localStorage.getItem("__token__");
    if (token) {
      next();
    }else { 
      next({
          path: '/login',//返回登录界面
      })
    }
  } else {
    next();
  }
  // const whiteList = ['Login', 'NotFund']
  // const token = localStorage.getItem("token")
  // if (!token && whiteList.indexOf(to.name) === -1) {
  //   next({ name: 'Login' })
  // } else {
  //   next()
  // }
})

// router.afterEach((to) => {
//   if (to.name && to.meta.needCache) {
//     store.commit('tagsView/ADD_CACHE_VIEW', to.name)
//   }

//   if (to.meta === undefined || to.meta.notNeedAuth) {
//     return
//   }

//   if (to.meta.fixed) {
//     store.dispatch('tagsView/addFixedVisitedView', {
//       name: to.name,
//       fullPath: to.fullPath,
//       meta: to.meta,
//     })
//   } else {
//     store.commit('tagsView/ADD_VISITED_VIEW', {
//       name: to.name,
//       fullPath: to.fullPath,
//       meta: to.meta,
//     })
//   }
// })

export default router
