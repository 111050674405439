import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import CONFIG from './config'
const baseURL = CONFIG.BASE_URL;

let defaultParams = {
  token: localStorage.getItem("__token__") || '',
}

function checkToken () {
  if (localStorage.getItem("__token__")) {
    defaultParams['token'] = localStorage.getItem("__token__")
  }
}

const service = axios.create({
  baseURL: baseURL,
  timeout: 50000
})


service.interceptors.response.use(
  (res) => {
    if (res && res.data) {
      if(res.data.code == 105 || res.data.code == 104 ){
        MessageBox.confirm('您已经退出登录状态，您可以点击取消留在当前页面或者重新登录', {
          confirmButtonText: '重新登录',
          showCancelButton:false,
          type: 'warning',
        }).then(() => {
          localStorage.clear()
          location.reload()
        }).catch(() => {})
      } else if (res.data.code == 106){
        Message.error('当前功能没有权限')
      } else if(res.data.code != 200){
        Message.error(res.data.message)
      } 
    }
    return res;
  },
  (err) => {
    if (err && err.response) {
      if(err.response.data.code != 200){
        Message.error(err.response.data.message)
      }
    } else {
      err.message = "连接到服务器失败";
    }
    return Promise.resolve(err.response);
  }
)

service.get = function (url, param) {
  checkToken()
  return new Promise((resolve, reject) => {
    service({
      method: "get",
      url,
      params: { ...param },
      headers:{
        "token" : defaultParams['token']
      }
    }).then(res => {
      resolve(res.data);
    }).catch(err => {
      reject(err);
    });
  });
},
service.getBlob = function (url, param) {
  checkToken()
  return new Promise((resolve, reject) => {
    return service({
      url,
      method: 'GET',
      params: { ...param },
      responseType: 'blob',
      headers:{
        "token" : defaultParams['token']
      }
    }).then(res => {
      resolve(res);
    }).catch(err => {
      reject(err);
    });
  });
},
service.post = function (url, param) {
  // const { token } = defaultParams
  return new Promise((resolve, reject) => {
    checkToken()
    service({
      method: "post",
      url,
      data: { ...param },
      headers:{
        "token" : defaultParams['token']
      }
    }).then(res => {
      resolve(res.data);
    }).catch(err => {
      Message.error(err.message)
      reject(err);
    });
  });
};



export default service
