import Layout from '@/Layout/index.vue'
export default {
  path: '/account',
  name: 'account',
  auth_key:"account",
  meta: { title: '账号管理', hidden: false,requireAuth: true },
  component: Layout,
  children: [
    {
      path: 'accountList',
      name: 'accountList',
      auth_key:'accountList',
      meta: { title: '账号列表', hidden: false,requireAuth: true },
      component: () => import('@/views/account/account-list.vue')
    },
    // {
    //   path: 'accountRole',
    //   name: 'accountRole',
    //   auth_key:'accountRole',
    //   meta: { title: '角色列表', hidden: false,requireAuth: true },
    //   component: () => import('@/views/account/account-role.vue')
    // },
    {
      path: 'accountModal',
      name: 'accountModal',
      auth_key:'accountModal',
      hidden: true,
      meta: { title: '账号详情', hidden: true,requireAuth: true },
      component: () => import('@/views/account/components/account-modal.vue')
    },
  ]
} 
