import Layout from '@/Layout/index.vue'
export default {
  path: '/quotedPrice',
  name: 'quotedPrice',
  auth_key:"quotedPrice",
  meta: { title: '报价管理', hidden: false,requireAuth: true },
  component: Layout,
  children: [
    {
      path: 'quotedPriceList',
      name: 'quotedPriceList',
      auth_key:'quotedPriceList',
      meta: { title: '报价列表', hidden: false,requireAuth: true },
      component: () => import('@/views/quotedPrice/quoted-price-list')
    },
    {
      path: 'quotedPriceModal',
      name: 'quotedPriceModal',
      auth_key:'quotedPriceModal',
      hidden: true,
      meta: { title: '报价详情', hidden: true,requireAuth: true },
      component: () => import('@/views/quotedPrice/components/quoted-price-modal.vue')
    },
    {
      path: 'otherConfiguration',
      name: 'otherConfiguration',
      auth_key:'otherConfiguration',
      hidden: true,
      meta: { title: '其他配置', hidden: true,requireAuth: true },
      component: () => import('@/views/quotedPrice/components/other-configuration.vue')
    },
  ]
} 