<template>
  <div class="app_main">
    <div class="apm_header">
      <SliderBar />
    </div>
    <div class="apm_main">
      <div class="breadcrumb" v-if="!$route.meta.hidden">
        <el-breadcrumb separator="/" >
          <el-breadcrumb-item v-for="(item,index) in routerList" :key="index">{{item.meta.title}}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="shmain">
        <router-view :key="$route.fullPath" />
      </div>
    </div>
  </div>
</template>

<script>
import SliderBar from "./SliderBar/index.vue";

export default {
  components: { SliderBar },
  data() {
    return {
      // cachedViews: this.$store.getters.cachedViews,
      routerList:[]
    };
  },
  watch:{
    $route(to){
      this.routerList = to.matched
    }
  },
  mounted(){
    this.routerList = this.$route.matched
  }
};
</script>
<style lang="scss" scoped>
.app_main {
  display: flex;
  flex-direction: column;
  background: #f5f7f9;
  width: 100%;
  height: 100vh;
  min-width: 1000px;
  overflow: hidden;
  .apm_main{
    flex: 1;
    overflow-y: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    .breadcrumb{
      margin-bottom: 16px;
    }
    .shmain{
      flex: 1;
      padding: 24px;
      background: #fff;
    }
  }
}
</style>
