import request from '@/utils/request'


export function Login (params) {
  return request.post('/api/login', params)
}

export function logout (params) {
  return request.post('/api/logout', params)
}

// 发送重置密码短信验证码
export function resetPasswordSmsCode (params) {
  return request.post('/api/resetPasswordSmsCode', params)
}

// 重置密码
export function resetPassword (params) {
  return request.post('/api/resetPassword', params)
}