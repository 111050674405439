import Layout from '@/Layout/index.vue'
export default {
  path: '/bill',
  name: 'bill',
  auth_key:"bill",
  meta: { title: '账单管理', hidden: false,requireAuth: true },
  component: Layout,
  children: [
    {
      path: 'billList',
      name: 'billList',
      auth_key:'billList',
      meta: { title: '账单列表', hidden: false,requireAuth: true },
      component: () => import('@/views/bill/bill-list.vue')
    },
    {
      path: 'billInfo',
      name: 'billInfo',
      auth_key:'billInfo',
      hidden: true,
      meta: { title: '账单详情', hidden: true,requireAuth: true },
      component: () => import('@/views/bill/bill-info.vue')
    },
    {
      path: 'repaymentList',
      name: 'repaymentList',
      auth_key:'repaymentList',
      meta: { title: '还款分账列表', hidden: false,requireAuth: true },
      component: () => import('@/views/bill/repayment-list.vue')
    },
    {
      path: 'stageList',
      name: 'stageList',
      auth_key:'stageList',
      meta: { title: '分期账单列表', hidden: false,requireAuth: true },
      component: () => import('@/views/bill/stage-list.vue')
    },
  ]
} 
