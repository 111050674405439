import Layout from '@/Layout/index.vue'
export default {
  path: '/collect',
  name: 'collect',
  auth_key:"collect",
  meta: { title: '数据汇总', hidden: false,requireAuth: true },
  component: Layout,
  children: [
    {
      path: 'businessLedgerCollect',
      name: 'businessLedgerCollect',
      auth_key:'businessLedgerCollect',
      meta: { title: '商家分账汇总', hidden: false,requireAuth: true },
      component: () => import('@/views/collect/business-ledger-collect')
    },
    {
      path: 'businessDataCollect',
      name: 'businessDataCollect',
      auth_key:'businessDataCollect',
      meta: { title: '商家数据汇总', hidden: false,requireAuth: true },
      component: () => import('@/views/collect/business-data-collect.vue')
    },
  ]
} 
