
export function getMyBrand() {
  try{
    const list = JSON.parse(localStorage.getItem("__manage_senior__"));
    if(list && list[0]){
      const item = list[0]
      return {
        id: item.id,
        name: item.headquarters_name
      }
    }
  }catch(err){
    console.log(err)
  }
  return null
}

export function getMyComp() {
  try{
    const list = JSON.parse(localStorage.getItem("__manage_entity__"));
    if(list && list[0]){
      const item = list[0]
      return {
        id: item.id,
        name: item.entity_name
      }
    }
  }catch(err){
    console.log(err)
  }
  return null
}

export function getMyShop() {
  try{
    const list = JSON.parse(localStorage.getItem("__manage_business__"));
    if(list.businessInfo && list.businessInfo[0]){
      const item = list.businessInfo[0]
      return {
        id: item.id,
        name: item.full_name
      }
    }
  }catch(err){
    console.log(err)
  }
  return null
}

export function getAuth(val) {
  var authMenu = localStorage.getItem('__auth__')
  let authArr = []
  var show = false
  try {
    authArr = JSON.parse(authMenu)
    authArr.map((item) => {
      if (item.auth_key == val) {
        show = true
      }
    })
  } catch (err) {
    console.log(err)
  }
  return show
}
